import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {ECaseUtilsGlobal} from '../eCaseUtils';
import {TitleService} from './title.service';

export class ProjectStatusModel {
  statusProject: Number;
  projectNumber: Number;

}

export interface RowsOption {
  labelEdit: { 'en': 'Edit'; 'fr': 'Modifier' };
  actionEdit: { 'edit'; 'disabled': false };
  labelArchived: { 'en': 'Archive'; 'fr': 'Archiver' };
  actionArchived: { 'archive'; 'disabled': false };
}

export interface ProjectInterface {
  projectNumber: Number;
  projectName: String;
  projectTeamLead: String;
  lastUpdate: Date;
  statusTermValue: String;
}


export class ApplicationStatusModel {
  statusApplication: Number;
  applicationId: Number;
  caseJsonDataRepositoryId: Number;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  initialQuestionsFormData;
  documentTypesObj;
  loggedUser;
  selectedChoicesForInitialQuestions;
  selectedProject;
  selectedApplication;
  showHeaderForProjectDetails;
  newProject;
  isCreateNewPaaForm: boolean;
  selectedForm;
  isNavigationFromHumanEthics: boolean;
  isNavigationFromGrants: boolean;
  isNavigatingFromProjects: boolean;
  sectionIndex;
  projects;
  isEditMode: boolean;
  isPaaEditMode: boolean;
  id: Number;
  row;
  appId;
  showsForm;
  isReadOnlyMode;
  selectedApplicationId;
  selectedPaaType;
  selectedPaaReportId;
  isNewApplicationCreated: boolean;
  humanEthicsTabs: boolean;
  isSupervisor: boolean;
  isUnderGradStudent: boolean;
  data;
  hasApplicationSaved: boolean;
  allPostApprovalApplications: any;
  programTypeClassificationId;
  competitionId;
  isGrantForm;
  isPaaApplication;
  isHumanticApplication;
  isUserLogOut: Boolean;
  setNumberOfAppsInActionRequired = new Subject();
  setActiveTab = new Subject();
  programCompetitionURL;
  programCompetitionID;
  triggerGraphData: Subject<any> = new Subject<any>();
  triggerPostApprovalGraphData: Subject<any> = new Subject<any>();
  allApplicationsForSelectedRole: any[];
  postApprovalAllApplications: any[];
  formLangArray: any[] = [];
  selectedTabTitle = '';
  selectedTabName;
  selectedTabPhaseTermId = 5530;
  selectedTabNotFoundMsgTitle = '';
  isBackClicked;
  refreshDataForArchiveApps: Subject<any> = new Subject<any>();
  isFormForConsent: boolean;
  isNotPublishedForm: boolean;
  projectId;
  // This is a back up array to re-initialize the graphs
  backUpArray: any[] = [];
  backUpQuickAccessArray: any[] = [];
  //for modal list of available organizations
  availableOrganizationsList = [];
  isForOrganization = false;
  restrictedSections: any[] = [];
  private projectTitleChangedByFormNewSubject = new Subject<any>();
  private _showSpinnerUpdateSubject = new Subject<any>();
  private allApplicationsUpdateSubject = new Subject<any>();
  private updateShowReviewerDashboardSubject = new Subject<any>();

  constructor(
    private eCaseHttpService: EcaseHttpService,
    private titleService: TitleService) {
    this.isUserLogOut = false;
  }

  get getTitleChangeInsideaFormSubjet() {
    return this.projectTitleChangedByFormNewSubject.asObservable();
  }

  get onAllApplicationsUpdate() {
    return this.allApplicationsUpdateSubject.asObservable();
  }

  get onShowReviewerFlagChange() {
    return this.updateShowReviewerDashboardSubject.asObservable();
  }

  get onSpinnerShowChange() {
    return this._showSpinnerUpdateSubject.asObservable();
  }

  notifyWhenaTitleChanges(event) {
    this.projectTitleChangedByFormNewSubject.next(event);
  }

  triggerApplicationIsArchived(flag) {
    this.refreshDataForArchiveApps.next(flag);
  }

  updateAllApplications(event) {
    this.allApplicationsUpdateSubject.next(event);
  }

  getAllApplications2(programTypeClassificationId: number): Observable<any> {
    return this.eCaseHttpService.post('/api/getAllApplications2', {'programTypeClassification': programTypeClassificationId});
  }

  getApplicationDetailsById(applicationId: number): Observable<any> {
    return this.eCaseHttpService.get('/api/getApplicationDetailsById/' + applicationId);
  }

  setTitleAccordingToCompetition(programType, tab) {
    if (programType === ECaseUtilsGlobal.EXCLUSIVELY_FOR_CONFORMITY_PROGRAM_TYPE_CLASSIFICATION) {
      this.titleService.setTitleWithSeparator(['ecase.researcherdasboard.projects.humanEthics', tab], ' | ');
    } else if (programType === ECaseUtilsGlobal.CLASSIC_GRANTS_PROGRAM_TYPE_CLASSIFICATION) {
      this.titleService.setTitleWithSeparator(['ecase.researcherdasboard.projects.grants', tab], ' | ');
    }

  }

  triggerWhenGraphDataIsLoaded(data) {
    this.triggerGraphData.next(data);
  }

  triggerWhenPostAprovalGraphDataIsLoaded(data) {
    this.triggerPostApprovalGraphData.next(data);
  }

  changeReviewerDashboardFlag(event) {
    this.updateShowReviewerDashboardSubject.next(event);
  }

  changeSpinnerStatus(event) {
    this._showSpinnerUpdateSubject.next(event);
  }

  getTeamMemberConsentInformation(caseApplicationId) {
    return this.eCaseHttpService.post('/api/getTeamMemberConsentInformation', {'caseApplicationId': caseApplicationId});
  }

  getCaseApplicationTeamMembers(caseApplicationId) {
    return this.eCaseHttpService.get('/api/getCaseApplicationTeamMembers/' + caseApplicationId);
  }

  getApplicationDelegates(caseApplicationId) {
    return this.eCaseHttpService.get('/api/getApplicationDelegates/' + caseApplicationId);
  }

  addUpdateApplicationDelegate(caseApplicationId, row) {
    return this.eCaseHttpService.post('/api/addUpdateApplicationDelegate', {
      'caseApplicationId': caseApplicationId,
      'delegate': row
    });
  }

  deleteApplicationDelegate(caseApplicationId, id) {
    return this.eCaseHttpService.post('/api/deleteApplicationDelegate', {
      'id': id,
      'caseApplicationId': caseApplicationId
    });
  }


  getTaskFormSections(formIds) {
    return this.eCaseHttpService.post('/api/getTaskFormSections', {
      'formIds': formIds
    });
  }

  delegationValidateEmailAddress(emailAddress) {
    return this.eCaseHttpService.post('/api/delegationValidateEmailAddress', {
      'emailAddress': emailAddress
    });
  }

  getPaReportsForApplication(caseApplicationId) {
    return this.eCaseHttpService.post('/api/getPostAwardApplications', {'caseApplicationId': caseApplicationId});
  }

  getPostAwardReportHistory(reportId) {
    return this.eCaseHttpService.get('/api/getPostAwardReportHistory/' + reportId);
  }

  createPaReport(obj) {
    return this.eCaseHttpService.post('/api/createPaReport', obj);
  }

  archivePaReport(paReportId) {
    return this.eCaseHttpService.get('/api/archivePaReport/' + paReportId);
  }

  getPostAwardReportHistoryForCandidate(paReportId) {
    return this.eCaseHttpService.get('/api/getPostAwardReportHistoryForCandidate/' + paReportId);
  }

  getAllProjects(): Observable<ProjectInterface[]> {
    return this.eCaseHttpService.post<ProjectInterface[]>('/api/getAllProject', {'competitionId': this.programTypeClassificationId});
  }

  getMyApplications() {
    return this.eCaseHttpService.post('/api/getMyApplications', {'competitionId': this.programTypeClassificationId});
  }

  removeKeyListFromSession(keys) {
    return this.eCaseHttpService.post('/api/removeKeyListFromSession', keys);
  }

  removeKeyListFromSessionNoWindowID(keys) {
    return this.eCaseHttpService.post('/api/removeKeyListFromSessionNoWindowId', keys);
  }

  getAwfInformationForCandidate(projectId) {
    return this.eCaseHttpService.post('/api/getAwfInformationForCandidate', {
      'projectId': projectId
    });
  }

  saveAwfInformationForCandidate(awfInfo) {
    return this.eCaseHttpService.post('/api/saveAwfInformationForCandidate', awfInfo);
  }

  viewFinancialOfferAndAgreement(awfId: number) {
    return this.eCaseHttpService.get('/api/viewFinancialOfferAndAgreement/' + awfId);
  }


  getFormFromVCpmCompetitionPublishedClassicalGrant(competitions, cpmCompetitionFormId) {
    return this.eCaseHttpService.post('/api/getFormFromVCpmCompetitionPublishedClassicalGrant', {
      'competitions': competitions,
      'cpmCompetitionFormId': cpmCompetitionFormId
    });
  }

  getFormFromCompetitionIds(competitions) {
    return this.eCaseHttpService.post('/api/getFormFromCompetitionIds', {'competitions': competitions});
  }

  getMyInvolvementApplications() {
    return this.eCaseHttpService.post('/api/getMyInvolvementApplications', {'competitionId': this.programTypeClassificationId});
  }

  getAllProjectsTeams(): Observable<ProjectInterface[]> {
    return this.eCaseHttpService.post<ProjectInterface[]>('/api/getProjectTeam ', {'competitionId': this.programTypeClassificationId});
  }


  getAllActiveCompetitions() {
    return this.eCaseHttpService.get('/api/getAllActiveCompetitions');
  }

  updateStautsProject(projectStatus: ProjectStatusModel) {
    return this.eCaseHttpService.post('api/updateStatusProject ', {'newStatus': projectStatus});
  }

  updateStatusApplication(updateApplicationStatus: ApplicationStatusModel) {
    return this.eCaseHttpService.post('api/updateStatusApplication ', {'newStatusApplication': updateApplicationStatus});
  }

  updateStatusApplicationForPAA(updateApplicationStatus: ApplicationStatusModel) {
    return this.eCaseHttpService.post('api/updateStatusApplicationPAA ', {'newStatusApplication': updateApplicationStatus});
  }

  getFormDataForFields(competitions, isFormForConsent?) {
    return this.eCaseHttpService.post('/api/getFormDataForFields', {
      'competitions': competitions ? competitions : [],
      'projectId': this.selectedProject ? this.selectedProject.projectNumber : -1,
      'projectTitle': this.selectedProject ? this.selectedProject.projectTitle : 'new project',
      'applicationTitle': this.selectedForm.title ? this.selectedForm.title : 'New application',
      'formId': this.selectedForm.formId ? this.selectedForm.formId : this.selectedForm.formID,
      'caseJsonDataRepositoryId': this.selectedForm.caseJsonRepositoryId ? this.selectedForm.caseJsonRepositoryId : -1,
      'teamMemberId': this.selectedProject ? (this.selectedProject.teamMemberId ? this.selectedProject.teamMemberId : -1) : -1,
      'isFormForConsent': isFormForConsent
    });
  }

  getFormDataForFieldsWithoutTeam(competitions, isFormForConsent?) {
    console.log(this.selectedProject);
    return this.eCaseHttpService.post('/api/getFormDataForFieldsWithoutTeam', {
      'competitions': competitions ? competitions : [],
      'projectId': this.selectedProject ? this.selectedProject.projectNumber : -1,
      'projectTitle': this.selectedProject ? this.selectedProject.projectTitle : 'new project',
      'applicationTitle': this.selectedForm.title ? this.selectedForm.title : 'New application',
      'formId': this.selectedForm.formId ? this.selectedForm.formId : this.selectedForm.formID,
      'caseJsonDataRepositoryId': this.selectedForm.caseJsonRepositoryId ? this.selectedForm.caseJsonRepositoryId : -1,
      'teamMemberId': this.selectedProject ? (this.selectedProject.teamMemberId ? this.selectedProject.teamMemberId : -1) : -1,
      'caseApplicationId': this.selectedProject ? (this.selectedProject.caseApplicationId ? this.selectedProject.caseApplicationId : -1) : -1,
      'isFormForConsent': isFormForConsent
    });
  }

  getInformationForConsent(caseApplicationId, roleId) {
    return this.eCaseHttpService.post('/api/getInformationForConsent', {
      'caseApplicationId': caseApplicationId,
      'roleId': roleId
    });
  }

  getPaaFormDataForFields() {
    return this.eCaseHttpService.post('/api/getPaaFormDataForFields', {
      'isSuperVisor': this.isSupervisor ? this.isSupervisor : false,
      'paaReportId': this.selectedPaaReportId ? this.selectedPaaReportId : -1,
      'caseJsonDataRepositoryId': this.selectedForm.caseJsonDataRepositoryId ? this.selectedForm.caseJsonDataRepositoryId : -1,
      'teamMemberId': this.selectedProject ? (this.selectedProject.teamMemberId ? this.selectedProject.teamMemberId : -1) : -1
    });
  }

  addApplicationsToProject(competitions) {
    return this.eCaseHttpService.post('/api/addApplicationsToProject', {
      'competitions': competitions,
      'projectId': this.selectedProject
    });
  }

  addProjectAndApplication(competitions) {
    return this.eCaseHttpService.post('/api/addProjectAndApplication', {
      'competitionIds': competitions,
      'startDate': '',
      'endDate': '',
      'projectTitle': 'new project',
      'initialQuestionForm': {}
    });
  }

  addProjectAndApplicationWithActiviti(competitions, createdBy) {
    const data: any = {};
    data.url = 'http://localhost:8085/api/process/start/preApprovalHumanEthics';
    data.data = {};
    data.data.actions = ['submit'];
    data.data.prj = {
      'competitionIds': competitions,
      'startDate': '',
      'createdBy': createdBy,
      'endDate': '',
      'projectTitle': 'new project',
      'initialQuestionForm': {}
    };
    return this.eCaseHttpService.post('/api/callworkflow', data);
  }

  getFormsForSelectedProjectTeam() {

    return this.eCaseHttpService.post('/api/getFormsForSelectedProjectTeam', {
      'projectId': this.selectedProject.projectNumber ? this.selectedProject.projectNumber : this.selectedProject,
      'roleId': this.selectedProject.roleId ? this.selectedProject.roleId : -1,
      'cpmCompetitionId': this.selectedProject.cpmCompetitionId ? this.selectedProject.cpmCompetitionId : -1,
      'teamMemberId': this.selectedProject.teamMemberId ? this.selectedProject.teamMemberId : -1
    });
  }

  getFormsForSelectedProject() {

    return this.eCaseHttpService.post('/api/getFormsForSelectedProject', {
      'projectId': this.selectedProject.projectNumber ? this.selectedProject.projectNumber : this.selectedProject,
      'roleId': this.selectedProject.roleId ? this.selectedProject.roleId : -1,
      'cpmCompetitionId': this.selectedProject.cpmCompetitionId ? this.selectedProject.cpmCompetitionId : -1,
      'teamMemberId': this.selectedProject.teamMemberId ? this.selectedProject.teamMemberId : -1
    });
  }

  getAllApplications(programTypeClassificationTermId) {
    return this.eCaseHttpService.post('/api/getAllApplications', {'programTypeClassification': parseInt(programTypeClassificationTermId, 10)});
  }

  getApplicationComments(applicationId) {
    return this.eCaseHttpService.get('/api/getApplicationComments/' + applicationId);
  }


  getPostApprovalApplications() {
    return this.eCaseHttpService.post('/api/getPostApprovalApplications', {'competitionId': this.programTypeClassificationId});
  }

  getAccessTypes() {
    return this.eCaseHttpService.post('/api/getAccessLevelTypesForPostApprovalApplications', {'competitionId': this.programTypeClassificationId});
  }

  getPendingPostApprovalApplications(competitionId) {
    return this.eCaseHttpService.post('/api/getPendingPostApprovalApplications', {'competitionId': competitionId});
  }


  getPendingApplications(competitionId) {
    return this.eCaseHttpService.post('/api/getPendingApplications', {'competitionId': competitionId});
  }


  getNewPaaInternalId(caseApplicationId) {
    return this.eCaseHttpService.post('/api/getNewPaaInternalId', {'caseApplicationId': caseApplicationId});
  }

  getApprovedApplicationData(isShowApproved, competitionId) {
    return this.eCaseHttpService.post('/api/getApprovedApplicationsData', {
      'isShowApproved': isShowApproved,
      'competitionId': competitionId
    });
  }

  getClosedApplication(isShowApproved, competitionId) {
    return this.eCaseHttpService.post('/api/getClosedApplication', {
      'isShowApproved': isShowApproved,
      'competitionId': competitionId
    });
  }


  getApprovedApplicationDataForPi(isShowApproved, competitionId) {
    return this.eCaseHttpService.post('/api/getApprovedApplicationsDataForPi', {
      'isShowApproved': isShowApproved,
      'competitionId': competitionId
    });
  }

  updateJsonDataRepository(infoAppJson) {
    return this.eCaseHttpService.post('/api/updateJsonRepositoryData', infoAppJson);
  }

  getAvailableLanguagesForCompetitionForm(competitionId, formId, isFormForConsent?) {
    return this.eCaseHttpService.post('/api/getAvailableLanguagesForCompetitionForm', {
      'cpmCompetitionId': competitionId,
      'selectedForm': formId,
      'isFormForConsent': isFormForConsent
    });
  }

  getUpdatedLovs(formId, isFormForConsent?, isNotPublishedForm?) {
    return this.eCaseHttpService.post('/api/getUpdatedLovs', {
      'formId': formId,
      'isFormForConsent': isFormForConsent,
      'isNotPublishedForm': isNotPublishedForm
    });

  }

  updatePaaJsonDataRepository(infoAppJson) {
    return this.eCaseHttpService.post('/api/updateJsonDataRepositoryForPAA', infoAppJson);

  }

  visitAttachedDocuments(applicationId) {
    return this.eCaseHttpService.get('/api/visitAttachedDocuments/' + applicationId);
  }

  visitPaAttachedDocuments(paReportId) {
    return this.eCaseHttpService.get('/api/visitPaAttachedDocuments/' + paReportId);
  }

  setListKeysInSession(keyValue) {
    return this.eCaseHttpService.post('/api/setKeyValueListInSession', keyValue);
  }

  setKeyInSession(keyValue) {
    return this.eCaseHttpService.post('/api/setKeyValueInTheSession', keyValue);
  }

  viewAdminDocuments(applicationId) {
    return this.eCaseHttpService.post(' /api/viewAdminDocuments', {'applicationId': applicationId});
  }

  viewAdminDocumentsPAA(paReportId) {
    return this.eCaseHttpService.post('/api/visitAttachedDocumentsAdminPaa ', {'paReportId': paReportId});
  }

  getListKeysInSession(keys) {
    return this.eCaseHttpService.post('/api/getListKeyFromSession', keys);
  }

  getEndDateOfCompetition(competitionId) {
    return this.eCaseHttpService.post('/api/getEndDateOfCompetition', {'competitionId': competitionId});

  }

  getMyProjects() {
    return this.eCaseHttpService.get('/api/myProjects');
  }

  addNewApplicationDocumentByApplicant(eCaseHttpService: EcaseHttpService, formData: FormData): Observable<any> {
    return eCaseHttpService.post('/api/addNewApplicationDocumentByApplicant', formData);
  }
}
